

var app = null;

jQuery.noConflict();

(function($) {

	'use strict'; // Use strict mode inside class, not in the global scope

	function sfg(settings) {

		var $this = this;

		var defaults = {};

		// Settings
		$this.settings = $.extend({}, defaults, settings || {});

		// Init
		$this.init();
	}

	sfg.prototype = {

		// Animation
		animSpeed: {
			faster: 100,
			fast: 200,
			medium: 400,
			slow: 500,
			slower: 800
		},


		/********************************************************
		* Constructor method
		*******************************************************/
		init: function() {

			var $this = this;

			// Global events
			$this.globalEvents();

			
		},


		/********************************************************
		* Events called in every section
		*******************************************************/
		globalEvents: function() {

			var $this = this;

			
			/**
			* Open mobile menu
			*/
			$(document).on('click touchstart', '#main-nav-handheld', function(event) {
				event.preventDefault();

				$('body').toggleClass('mobile-menu-open');
				$('#main-nav-handheld').toggleClass('open');
			});

			$(document).on('click touchstart', '#overlay-handheld', function(event) {
				event.preventDefault();

				$('body').removeClass('mobile-menu-open');
				$('#main-nav-handheld').removeClass('open');
			});

			
			$(document).on('click touchstart', '#toggle .title', function(event) {
				event.preventDefault();

				var $panel = $(this).closest('.panel'),
					$panelActive = $('.panel.active');

				// If not already active
				if (!$panel.hasClass('active')) {
					// Remove old panel
					$panelActive.toggleClass('active').find('.content').slideToggle(400);

					// Active new panel
					$panel.addClass('active').find('.content').slideToggle(400);
				}
			});
		}

	};


	/********************************************************
	*  Create instance
	*******************************************************/
	$.sfg = function(settings) {

		return new sfg(settings);
	};

	app = $.sfg({
		baseURL: ''
	});


}(jQuery));